/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Roboto:500,400,300");

@import "./styles/timepicker";
@import "./styles/theme";
@import "./styles/helper";
@import "./styles/dialog";
@import "./styles/chip";
@import "./styles/table";
@import "./styles/fab";
@import "./styles/color";
@import "./styles/icon";
@import "~leaflet/dist/leaflet.css";
@import "leaflet-sidebar-v2/css/leaflet-sidebar.min.css";
@include mat-core();
// @import "~ng-pick-datetime/assets/style/picker.min.css";
@include angular-material-theme($app-theme);
.dark-theme {
  @include angular-material-theme($dark-theme);
}

html {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
}

.tkg-chips-icon {
  cursor: pointer;
}

#app-content {
  height: calc(100% - 64px);

  @media screen and (max-width: 599px) {
    height: calc(100% - 56px);
  }

  .main-gap-only-secure {
    padding: 24px;
  }
  @media screen and (min-width: 960px) {
    main {
      padding: 24px;
    }
  }
}

.auth {
  #app-content {
    height: 100%;
  }
}

mat-sidenav-container {
  margin: 0;
  width: 100%;
  height: 100%;
}

/* Application styles */
.mat-hint.mat-error {
  color: #f44336 !important;
}

.auth .logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  width: 260px;

  @media screen and (max-width: 667px) {
    width: 180px;
  }

  @media screen and (max-width: 667px) and (orientation: landscape) {
    margin-top: 50px;
  }
}

.auth mat-card {
  width: 450px;

  @media screen and (max-width: 667px) and (orientation: landscape) {
    margin-bottom: 50px;
  }
}

.mat-fab-bottom-right {
  position: fixed !important;
  bottom: 16px !important;
  right: 16px !important;
  z-index: 100;
}

.mat-fab-menu-item {
  background: #333333;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: flex-end;
  line-height: 25;
  padding: 2px 7px;
  border-radius: 3px;
  bottom: 20px;
  right: 25px;
  font-size: 12px;
  margin-top: 4px;

  //Figure out color, separate items with spaces

  button span {
    color: white;
  }
}

.mat-paginator-page-size {
  @media screen and (max-width: 667px) and (orientation: portrait) {
    display: none !important;
  }
}

// Todo: move this to src/app/shared/dialog-confirm/dialog-confirm.component.scss
app-dialog-confirm {
  .mat-dialog-content {
    h3 {
      .mat-body-2 {
        font-size: 16px !important;
      }
    }
  }
}

.mat-sidenav {
  .mat-divider {
    border-top-color: rgba(255, 255, 255, 0.12);
  }
}

.list-empty {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  .mat-icon {
    @include mat-icon-size(72px);
  }
}

.mat-tooltip.tooltip {
  font-size: 13px;
}

.checkin-dialog-container .mat-dialog-container {
  padding: 0;
  max-height: 100vh;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  max-width: 100vh !important;
  min-height: 100vh !important;
  min-width: 100vw !important;
}

app-sheduling-dialog {
  .mat-input-element {
    text-transform: uppercase !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0.7em 0.75em 0 0.75em !important;
  }
}

app-event-departments {
  .mat-expansion-panel-content {
    text-transform: uppercase !important;
  }
}

app-report-dynamic-filter {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    border-radius: 15px 15px 15px 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(192, 192, 192);
  }
}

app-checkin-list {
  .mdc-evolution-chip__text-label {
    color: white !important;
  }

  @media screen and (max-width: 768px) {
    .mat-form-field-wrapper {
      margin: 1.5em 0 !important;
      width: 172px !important;
    }
  }

  @media screen and (max-width: 425px) {
    .container-filters {
      display: grid !important;
      grid-template-columns: 200px 200px;
      grid-row: auto;
      margin: 0 0 0 6%;
    }

    .mat-form-field-wrapper {
      margin: 0.5em 0 !important;
      width: 172px !important;
    }
  }

  @media screen and (max-width: 375px) {
    .container-filters {
      margin: 0 0 0 3%;
    }

    .mat-form-field-wrapper {
      width: 154px !important;
    }
  }

  @media screen and (max-width: 320px) {
    .container-filters {
      display: inline !important;
      margin: 0 0 0 3%;
    }

    .mat-form-field-wrapper {
      width: 300px !important;
    }
  }
}
