@media screen and (max-width: 959px) {
  .dialog-small {
    width: 80vw !important;
    max-width: 80vw !important;
  }

  .dialog-fullscreen {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
  }

  .dialog-fullscreen-np {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
    .mat-dialog-container {
      padding:0;
    }
  }

}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  .dialog-medium,
  .dialog-large {
    width: 80vw !important;
    max-width: 80vw !important;
  }
  .dialog-large-black {
    // width: 80vw !important;
    max-height: 100vh;
    width: 100%;
    height: 100vh;
    margin:0 auto;
    max-height: 100vh !important;
    max-width: 100vh !important;
    min-height: 100vh !important;
    min-width: 100vw !important;
    .mat-dialog-container {
      background: black !important;
    }
  }

  .dialog-full {
    // width: 80vw !important;
    max-height: 100vh;
    width: 100%;
    height: 100vh;
    margin:0 auto;
    max-height: 100vh !important;
    max-width: 100vh !important;
    min-height: 100vh !important;
    min-width: 100vw !important;
  }

  .dialog-small {
    width: 40vw !important;
    max-width: 40vw !important;
  }
}

@media screen and (min-width: 1280px) {
  .dialog-small {
    width: 30vw !important;
    max-width: 30vw !important;
  }

  .dialog-small-plus {
    width: 45vw !important;
    max-width: 45vw !important;
  }

  .dialog-width-45vw {
    width: 100%;
    max-width: 45vw !important;
  }

  .dialog-medium {
    width: 65vw !important;
    max-width: 65vw !important;
  }

  .dialog-large {
    width: 80vw !important;
    max-width: 80vw !important;
  }


  .dialog-large-black {
    // width: 80vw !important;
    max-height: 100vh;
    width: 100%;
    height: 100vh;
    margin:0 auto;
    max-height: 100vh !important;
    max-width: 100vh !important;
    min-height: 100vh !important;
    min-width: 100vw !important;
    // max-width: 80vw !important;
    .mat-dialog-container {
      background: black !important;
    }
  }

  .dialog-fullscreen-np {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
    .mat-dialog-container {
      padding:0;
    }
  }

  .dialog-full {
    // width: 80vw !important;
    max-height: 100vh;
    width: 100%;
    height: 100vh;
    margin:0 auto;
    max-height: 100vh !important;
    max-width: 100vh !important;
    min-height: 100vh !important;
    min-width: 100vw !important;
  }

  .dialog-fullscreen.monitor-fullscreen {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
  }
}

.mat-dialog-container {
  .mat-dialog-actions {
    .mat-button {
      text-transform: uppercase;
    }
  }
}

.mat-dialog-close {
  position: absolute !important;
  top: 8px !important;
  right: 20px !important;
  min-width: 0px !important;
  padding: 0px 2px !important;
}

.monitor-fullscreen {
  & .mat-dialog-container {
    padding: 0 !important;
  }
}

.mat-select-panel {
  margin: 45px 0 0 8px !important;
}
