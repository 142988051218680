

.app-chip-blue, .app-chip-primary {
  background-color: rgb(27, 184, 215) !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}


.app-chip-violeta {
  background-color: rgb(74, 10, 104) !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}


.app-chip-denied {
  background-color: #EC273B !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-waiting {
  background-color: #FFAA00 !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-directed_park {
  background-color: #9C27B0 !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-directed_dock {
  background-color: #9C27B0 !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-directed_dfd {
  background-color: #9C27B0 !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-on_park {
  background-color: #6200EE !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-on_park_loaded {
  background-color: #b1aed4 !important;
  color: #000000 !important;
  border: 1px solid #ffffff;
}

.app-chip-on_dock {
  background-color: #1CB1A2 !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}
.app-chip-on_dfd {
  background-color: #4094FF !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-finished_loading {
  background-color: #E1094C !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-shipping_finished {
  background-color: #36577B !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-fetch_invoice {
  background-color: #57CD2F !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-fetch_invoice_dfd {
  background-color: #57CD2F !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}


.app-chip-finished {
  background-color: #A21846 !important;
  color: #ffffff !important;
}
//---------modal-painel-status.component.scss------------------------------
.app-chip-denied-panel {
  color: #EC273B !important;
}

.app-chip-waiting-panel {
  color: #FFAA00 !important;

}

.app-chip-directed_park-panel {
  color: #9C27B0 !important;

}

.app-chip-directed_dock-panel {
  color: #9C27B0 !important;

}

.app-chip-directed_dfd-panel {
  color: #9C27B0 !important;
}

.app-chip-on_park-panel {
  color: #6200EE !important;
}

.app-chip-on_dock-panel {
  color: #1CB1A2 !important;

}
.app-chip-on_dfd-panel {
  color: #4094FF !important;

}

.app-chip-finished_loading-panel {
  color: #E1094C !important;
}

.app-chip-shipping_finished-panel {
  color: #36577B !important;
}

.app-chip-fetch_invoice-panel {
  color: #57CD2F !important;
}

.app-chip-fetch_invoice_dfd-panel {
  color: #57CD2F !important;
}

.app-chip-finished-panel {
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

.app-chip-validated_doc {
  color: #ffffff !important;
  background-color: #1d4410 !important;
  border: 1px solid #ffffff;
}

.app-chip-validated_vehicle {
  color: #ffffff !important;
  background-color: #33d6c6 !important;
  border: 1px solid #ffffff;
}

.app-chip-invalidated_doc {
  color: #ffffff !important;
  background-color: #A21846 !important;
  border: 1px solid #ffffff;
}

.app-chip-invalidated_vehicle {
  color: #ffffff !important;
  background-color: #EC273B !important;
  border: 1px solid #ffffff;
}

.app-chip-authorized_exit {
  color: #ffffff !important;
  background-color: #96A300 !important;
  border: 1px solid #ffffff;
}

.app-chip-null {
  color: #ffffff !important;
  background-color: #e2e6eb !important;
  border: 1px solid #ffffff;
}

.app-chip-pendency {
  color: #ffffff !important;
  background-color: #EC273B !important;
  border: 1px solid #ffffff;
}

.app-chip-invoice_delivered {
  color: #ffffff !important;
  background-color: #A21846 !important;
  border: 1px solid #ffffff;
}


//---------painelmotorista-list.component.scss------------------------------




body {
  background-color: #F8F8F8;
}

.espaco_width {
  margin-left: 20px;

}

.example-full-width {
  width: 100%;
}

.fontBusca{
  font-weight: 300;
  font-size: 14px;
  color: #768191;
}

.card_top {
  height: 58px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items:center;
  justify-content:center;
  padding-left: 9px;
  padding-right: 9px;
  border-radius:3px;
}

.cor_top_card{
  background: #f3f5f8;
}

.cor_top_card_vermelho{
  background:#ec372b;
  color:#ffffff;
}

.example-card {
  justify-content: flex-start !important ;
  align-items: flex-start !important;
  margin-top: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  max-width: 280px;
  max-height: 150px;
}



.foto_motorista{
  width: 25px;
  height: 25px;
  margin-right: 0px;
  border-radius: 50%;
  float: left;
}

.font_motorista{
  font-weight: 300;
  font-size: 11px;
  padding-left: 9px;
}

.placas{
  font-weight: 300;
  font-size: 8px;
  color: #99a2af;
  height: 10px;
}

.placasnumero{
  font-weight: 300;
  font-size: 11px;
  color: #1d2329;
}

.tempopermanencia{
  font-weight: 300;
  font-size: 11px;
  color: #718191;
}



.meio_meio{
  width: 50%;
  height: 38px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items:center;
  justify-content:center;
  padding-left: 9px;
}

.meio_card{
  background-color: #e8ebef;
  height: 38px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items:center;
  justify-content:center;
}

.circuloCAMINHAO{
  font-weight: 300;
  font-size: 11px;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items:center;
  justify-content:center;
  width:25px;
  height:25px;
  border-radius:15px;
  background:#7c8796;
  position:relative;
}

.primeira_linha{
  float: left;
}
.primeira_linha2{
  width: 17%;
}

.cd{
  font-weight: 300;
  font-size: 16px;
  padding-left: 9px;
  color: #7c8796;
}

.ultima{
  font-weight: 300;
  font-size: 12px;
  padding-left: 9px;
  color: #0191E6;
}

.circulo{
  font-weight: 300;
  font-size: 10px;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items:center;
  justify-content:center;
  width:95px;
  height:23px;
  border-radius:13px;
  position:relative;

  // border: 1px solid rgb(240, 236, 236);
  margin: auto;

  }

// .cor_verde{
//     background:#5ED237;
//   }

// .cor_amarelo {
//     background:#fd8509;
//   }

// .cor_vermelho{
//     background:#ec372b;
//     border: 1px solid #ffffff;
//   }

.circulotempo{
    font-weight: 300;
    font-size: 11px;
    color: #718191;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items:center;
    justify-content:left;
    width:100px;
    height:23px;
    border-radius:13px;
    background:#f3f5f8;
    position:relative;
    border: 1px solid rgb(240, 236, 236);
    // margin: auto;
  }

.circulo_local_atual{
    font-weight: 300;
    font-size: 8px;
    color: #0091e6;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items:center;
    justify-content:center;
    position:relative;
    margin-top: 5px;
  }

.footer_card {

    height: 56px;
    vertical-align: middle;
    background-color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items:center;
    justify-content:center;
    padding-left: 9px;
  }

.footer_01{
    width: 50%;
    vertical-align: middle;
    background-color: #fff;
    float: left;
    font-weight: 300;
    font-size: 8px;
  }

.font_localizacao{
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 8px;
  font-weight: 500;
}

.footer_02{
    font-weight: 300;
    font-size: 8px;
    color: #0091E6;
    width: 50%;
    vertical-align: middle;
    background-color: #fff;
    float: left;
    padding-top: 3%;
  }

.icon_LOCAL{
    color: #0091E6;
    font-size: 12px;
  }

.icon_hora{
    color: #0091E6;
    font-size: 10px;
    margin-right: 3px;
  }

.icon_corcaminhao{
    color: #fff;
    font-size: 13px;
  }

.tp-card{
  max-width: 30%;
}

.icon_cor{
    color: #5ED237;
  }

.todo_box {
  margin-left: 2%;
  float: left;
}

.fill {
  flex: 1;
}

.etapa-docas {
  margin-top: 60px !important;
  width: 90%;
  display: flex;
}

.indicacao_card {
  float: left;
  font-size: 1px
}

.meio_docas {
  margin: auto;
  padding-left: 16px;
  padding-right: 16px;
  height: 110px;
  align-self: flex-end;

}


.linha-horizontal {
  display: block; height: 1px;
    border: 0; border-top: 1px solid #b0b0b0;
    margin: 1em 0; padding: 0;
    margin-right: 30px;
}

//--------------------- direcionamento-list.component.scss

table {
  width: 100%;
}
.primeiralinha{
  font-weight: 300;
  font-size: 14px;
  color: #7C8796;
}
.primeira_a{
  font-weight: 300;
  font-size: 14px;
  color: #7C8796;
  float: left;
  margin-right: 20px
}
.primeira_data{
  float: left;
}

//--------------checkin-driver-parking.component.scss
.dialog-message {
  font-size: 14pt;
  padding-bottom: 16px;
}
.dialog-message- {
  font-size: 14pt;
  padding-bottom: 16px;
}
.mat-form-field {
  width: 100% !important;
}
.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
  margin-bottom: 16px;
}
.mat-align-left {
  text-align: left;
}
.mat-align-center {
  text-align: center;
}
.mat-align-right {
  text-align: right;
}

.mat-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 11px;
  font-weight: 500;
}
